import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {NocoDbProjectProxy} from "../javascript.lib.mojo-base/nocodb/NocoDbProjectProxy";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {FirebaseConnectionService} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";

@Injectable()
export class ProductService {

  private log = LoggerFactory.build( 'ProductService' );

  async ensureProduct(): Promise<void> {
    let version:string = '';

    const projectProxy = new NocoDbProjectProxy(environment.nocoDbConfig, environment.nocoDbConfig.projectTitle, this.firebase);
    const nocoProduct: NocoDbProduct = await NocoDbProduct.load(projectProxy);
    version = nocoProduct.version;

    environment.productConfig.product = nocoProduct.buildProduct(environment);

    if (version) {
      environment.productConfig.product.version = version;
    }
  }

  constructor(private firebase: FirebaseConnectionService) {
  }
}
