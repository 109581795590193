import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MyApp } from './app.component';
import {EvaluationComponentsModule} from "../browser.lib.evaluation-tool/module.evaluation-components/evaluation-components.module";
import { RegistrationProvider } from '../service.registration/registration';
import { PwaApplicationContextProvider } from '../service.pwa-application-context/pwa-application-context';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {environment} from "../environments/environment";
import { PhotosProvider } from '../browser.lib.evaluation-tool/module.evaluation-services/service.photos/photos';
import { UserProvider } from '../service.user/user';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  PwaSessionContextProvider
} from '../service.pwa-session-context/pwa-session-context';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AppRoutingModule} from "./app-routing.module";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {HomePage} from "../page.home/home";
import {MatInputModule} from "@angular/material/input";
import {CompletedPage} from "../page.completed/completed";
import {RoundProgressModule} from "angular-svg-round-progressbar";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRadioModule} from "@angular/material/radio";
import { FlexLayoutModule } from '@angular/flex-layout';
import {EvaluationPagesModule} from "../browser.lib.evaluation-tool/module.evaluation-pages/evaluation-pages.module";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {PageAspectSectionComponent} from "../page.aspect-section/page-aspect-section.component";
import {HotelSelectPage} from "../page.hotel-select/hotel-select";
import {BaseApplicationContext} from "../browser.lib.evaluation-tool/services.application-context/BaseApplicationContext";
import {BaseSessionContext} from "../browser.lib.evaluation-tool/service.session-context/BaseSessionContext";
import {BaseSideMenuModel} from "../browser.lib.evaluation-tool/service.side-menu-model/BaseSideMenuModel";
import {PwaSideMenuModel} from "../service.side-menu-model/PwaSideMenuModel";
import {FirebaseConnectionService} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {DocumentsComponent} from "../page.documents/documents.component";
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BaseComponentsModule} from "../browser.lib.evaluation-tool/module.base-components/base-components.module";
import {PasswordResetPage} from "../page.password-reset/password-reset.page";
import {
  SessionCheckerComponent
} from "../browser.lib.evaluation-tool/component.session-checker/session-checker.component";
import {ScoreComponent} from "../page.completed/component.score/score";
import {NgFireworksModule} from "@fireworks-js/angular";
import {PageHomeClusterComponent} from "../page.home-cluster/page-home-cluster.component";
import {PageClusterPageComponent} from "../page.cluster-page/page-cluster-page.component";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatTreeModule} from "@angular/material/tree";
import {ClusterComponent} from "../page.home-cluster/component.cluster/cluster.component";
import {
  ClusterEditDialogComponent
} from "../page.home-cluster/component.cluster/component.cluster-edit-dialog/cluster-edit-dialog";
import {ClusterAddDialogComponent} from "../page.home-cluster/component.cluster-add-dialog/cluster-add-dialog";
import {ClusterCommentDialogComponent} from "../page.home-cluster/component.cluster/component.cluster-comment/cluster-comment-dialog";
import {ProductService} from "../service.product/product";

@NgModule({
  declarations: [

    ClusterComponent,
    ClusterAddDialogComponent,
    ClusterCommentDialogComponent,
    ClusterEditDialogComponent,
    CompletedPage,
    DocumentsComponent,
    HomePage,
    HotelSelectPage,
    MyApp,
    PageHomeClusterComponent,
    PageClusterPageComponent,
    PageAspectSectionComponent,
    PasswordResetPage,
    ScoreComponent,
    SessionCheckerComponent,
  ],

  imports: [

    // Core ...
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,


    // Material ...
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTreeModule,


    // 3rd party ...
    AngularFireModule.initializeApp( environment.firebaseConfig ),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FontAwesomeModule,
    FlexLayoutModule,
    NgFireworksModule,
    RoundProgressModule,

    // In-House ...
    AppRoutingModule,
    BaseComponentsModule,
    EvaluationComponentsModule,
    EvaluationPagesModule,
  ],

  bootstrap: [MyApp],

  providers: [
    { provide: BaseApplicationContext, useExisting: PwaApplicationContextProvider },
    { provide: BaseSessionContext, useExisting: PwaSessionContextProvider },
    { provide: BaseSideMenuModel, useClass: PwaSideMenuModel },
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FirebaseConnectionService,
    PhotosProvider,
    ProductService,
    PwaApplicationContextProvider,
    PwaSessionContextProvider,
    RegistrationProvider,
    UserProvider,
  ]
})
export class AppModule {}
