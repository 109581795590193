import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {FirebaseConnectionService} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirestoreLoggerDelegate} from "../javascript.lib.mojo-base/log/FirestoreLoggerDelegate";
import {LoggerDelegate} from "../javascript.lib.mojo-base/log/LoggerDelegate";
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {XmlHttpNocoDbProxy} from "../javascript.lib.mojo-base/nocodb/XmlHttpNocoDbProxy";
import {NocoDbProjectProxy} from "../javascript.lib.mojo-base/nocodb/NocoDbProjectProxy";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {HttpGet} from "../javascript.lib.mojo-base/util/HttpGet";
import {INocoDbProductValue} from "../javascript.lib.mojo-base/nocodb/NocoDbProductValue";
import {ProductService} from "../service.product/product";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],
})
export class MyApp {

  private _log: ILogger = LoggerFactory.build( 'MyApp' );

  isTest: boolean = environment.isTest || environment.isDevelopment;


  constructor(public applicationContext: PwaApplicationContextProvider,
              public firebase: FirebaseConnectionService,
              public productService: ProductService) {

    if(!environment.isDevelopment) {
      LoggerDelegate.INSTANCE = new FirestoreLoggerDelegate(firebase.firestore);
    }

    this.productService.ensureProduct().then(this._log.info("done"));
  }
}

