import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";




export type NocoEvaluationSectionId = number;

export interface INocoEvaluationSection {

  Id: NocoEvaluationSectionId;
  ProductId: number;
  Title: string;
  SectionNo: number;
  FirebaseId: string;
}

export class NocoEvaluationSection extends ValueObject<INocoEvaluationSection>{

  protected onSetValue(value: INocoEvaluationSection | null) {
  }

  constructor( value: INocoEvaluationSection | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }
}


export class NocoEvaluationSectionSet {

  values: NocoEvaluationSection[] = [];
  valuesById: {[id: number]: NocoEvaluationSection} = {};



  getEvaluationSections( productId: number ): NocoEvaluationSection[] {

    const answer: NocoEvaluationSection[] = [];
    for( const candidate of this.values ) {

      if( candidate.value.ProductId === productId  ) {
        answer.push( candidate );
      }
    }

    return answer.sort( (a, b) => {

      return a.value.SectionNo - b.value.SectionNo;
    });

  }

  getEvaluationSection( productId: number, sectionNo: number ): NocoEvaluationSection {

    for( const candidate of this.values ) {

      if( candidate.value.ProductId === productId && candidate.value.SectionNo === sectionNo ) {
        return candidate;
      }
    }
    return null;
  }


  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {
    if(projectProxy.isNocoDbVersion202Plus) {
      return 'evaluation_section';
    }
    return 'EvaluationSection';
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEvaluationSection>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoEvaluationSection>( tableName );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEvaluationSectionSet> {

    const value = await NocoEvaluationSectionSet.getValue( proxy );
    return new NocoEvaluationSectionSet(value);
  }

  public constructor( public value: IListingReponse<INocoEvaluationSection> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoEvaluationSection( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
    }

  }

}

