import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app.module';
import {environment} from "../environments/environment";
import {DevelopmentConsoleLogger} from "../javascript.lib.mojo-base/log/DevelopmentConsoleLogger";
import {ProductionConsoleLogger} from "../javascript.lib.mojo-base/log/ProductionConsoleLogger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {TestConsoleLogger} from "../javascript.lib.mojo-base/log/TestConsoleLogger";
import {XmlHttpNocoDbProxy} from "../javascript.lib.mojo-base/nocodb/XmlHttpNocoDbProxy";
import {NocoDbProjectProxy} from "../javascript.lib.mojo-base/nocodb/NocoDbProjectProxy";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {HttpGet} from "../javascript.lib.mojo-base/util/HttpGet";
import {INocoDbProductValue} from "../javascript.lib.mojo-base/nocodb/NocoDbProductValue";
import {SignInParams} from "../browser.lib.evaluation-tool/module.base-components/component.sign-in/SignInParams";
import {FirebaseConnectionService} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";

if (environment.isProduction) {
  enableProdMode();
  LoggerFactory.init(ProductionConsoleLogger.factory);
} else if (environment.isTest) {
  LoggerFactory.init(TestConsoleLogger.factory);
} else {
  LoggerFactory.init(DevelopmentConsoleLogger.factory);
}

async function initApp() {

  SignInParams.initFromWindow(window);

  let version: string = null;

  // if (environment.nocoDbConfig) {
  //
  //   const proxy = new XmlHttpNocoDbProxy(environment.nocoDbConfig);
  //   await proxy.signIn();
  //
  //   const firebase = new FirebaseConnectionService();
  //   const projectList = await proxy.db_meta_project_list();
  //   const projectProxy = await NocoDbProjectProxy.build(proxy, environment.nocoDbConfig.projectTitle, projectList.list, firebase);
  //   const nocoProduct: NocoDbProduct = await NocoDbProduct.load(projectProxy);
  //   version = nocoProduct.version;
  //
  //   environment.productConfig.product = nocoProduct.buildProduct(environment);
  //
  // } else {
  //   const url = `${environment.firebaseConfig.databaseURL}/products/${environment.productConfig.productType}/current.json`;
  //   try {
  //
  //     const productValue = await HttpGet.execute<INocoDbProductValue>(url);
  //     if (productValue) {
  //       const nocoProduct: NocoDbProduct = NocoDbProduct.buildFromValue(productValue);
  //       version = nocoProduct.version;
  //       environment.productConfig.product = nocoProduct.buildProduct(environment);
  //     }
  //
  //   } catch (error) {
  //     console.warn('error', error);
  //   }
  // }
  //
  // if (version) {
  //   environment.productConfig.product.version = version;
  // }

  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
}

initApp();
