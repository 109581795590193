


export class BrowserAppEvaluationToolInfo {

  public version = '25.2.6.9';

  constructor() {
  }

}
