import {NocoDbProxy} from "./NocoDbProxy";
import {ITableListing, TableListing} from "./TableInformation";
import {IProjectInformation} from "./meta/ProjectInformation";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {IListingReponse} from "./ListingReponse";
import {FirebaseConnectionService} from "../../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirebaseNocoResponseCache} from "../firebase/realtime-database/product/FirebaseNocoResponseCache";
import {XmlHttpNocoDbProxy} from "./XmlHttpNocoDbProxy";
import {INocoDbConfiguration} from "../../environments/FacilitiesEvaluationEnvironment";

interface IFieldMap {
  fieldName: string;
  legacyName: string;
}

export class NocoDbProjectProxy {

  private static _log: ILogger = LoggerFactory.build('NocoDbProjectProxy');
  private fieldMaps: { [tableName: string]: IFieldMap[] } = {};

  public proxy: XmlHttpNocoDbProxy;
  public projects: IProjectInformation[];
  public projectInfo: IProjectInformation;
  public tableListing: TableListing;

  private async tryGetResponseFromCache<T>(table: string): Promise<IListingReponse<T>> {
    try {
      return await FirebaseNocoResponseCache.read<T>(this.firebase, table) as IListingReponse<T>;
    } catch (e) {
      NocoDbProjectProxy._log.error("Unable to read response from cache", "table", table, e);
    }
  }

  public get isNocoDbVersion202Plus() : boolean {
    if (!this.proxy) {
      return false;
    }
    return this.proxy.isNocoDbVersion202Plus;
  }

  private async tryWriteResponseToCache<T>(table: string, data: IListingReponse<T>): Promise<void> {
    try {
      await FirebaseNocoResponseCache.write<T>(this.firebase, table, data);
    } catch (e) {
      NocoDbProjectProxy._log.error("Unable to write response to cache", "table", table, e);
    }
  }

  private static _getTargetProject(projects: IProjectInformation[], projectTitle: string): IProjectInformation | null {

    this._log.debug('projects', projects);

    for (const candidate of projects) {
      if (projectTitle === candidate.title) {
        return candidate;
      }
    }

    NocoDbProjectProxy._log.error("project not found; returning null");
    return null;
  }

  mapToLegacyView<T>(table: string, viewResponse: IListingReponse<T>) {

    if (0 === viewResponse.list.length) {
      return;
    }

    let fieldMappings = this.fieldMaps[table];
    if (!fieldMappings) {

      this.fieldMaps[table] = fieldMappings = [];

      const row0 = viewResponse.list[0];

      for (const fieldName of Object.keys(row0)) {
        const tokens = fieldName.split('_');
        let legacyName = '';
        for (const token of tokens) {

          // [How do I make the first letter of a string uppercase in JavaScript? - Stack Overflow](https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript)
          legacyName += token.charAt(0).toUpperCase() + token.slice(1);
        }
        fieldMappings.push({
          fieldName,
          legacyName
        });
      }
    }

    for (let i = 0; i < viewResponse.list.length; i++) {
      const oldRow = viewResponse.list[i];
      const newRow = new Object();
      for (const fieldMapping of fieldMappings) {
        newRow[fieldMapping.legacyName] = oldRow[fieldMapping.fieldName];
      }
      // @ts-ignore
      viewResponse.list[i] = newRow;
    }

  }

  public async getView<T>(table: string, view: string = 'API'): Promise<IListingReponse<T>> {

    let response : IListingReponse<T>;
    response = await this.tryGetResponseFromCache(table);
    if (response) {
      return response;
    }

    await this.ensureConnection();

    // https://docs.nocodb.com/0.109.7/developer-resources/rest-apis/#query-params

    const limit = 1000;

    const url = `${this.proxy.nocoDbConfig.httpServer}/api/v1/db/data/v1/${this.projectInfo.id}/${table}/views/${view}?limit=${limit}`;
    NocoDbProjectProxy._log.debug('url', url);

    response = await this.proxy.get(url) as IListingReponse<T>;

    let offset = limit;
    let isLastPage = response.pageInfo.isLastPage;
    while (!isLastPage) {

      const pageUrl = `${url}&offset=${offset}`;
      NocoDbProjectProxy._log.debug('pageUrl', pageUrl);

      const pageResponse = await this.proxy.get(pageUrl) as IListingReponse<T>;
      NocoDbProjectProxy._log.debug('pageResponse', pageResponse);

      isLastPage = pageResponse.pageInfo.isLastPage;

      for (const e of pageResponse.list) {
        response.list.push(e);
      }

      offset += limit;
    }

    if (this.proxy.isNocoDbVersion202Plus) {
      this.mapToLegacyView(table, response);
    }

    NocoDbProjectProxy._log.debug('response', response);
    await this.tryWriteResponseToCache<T>(table, response);

    return response;
  }

  private async ensureConnection(): Promise<void> {

    if (this.projectInfo && this.tableListing) {
      return;
    }

    this.proxy = new XmlHttpNocoDbProxy(this.nocoDbConfig);
    await this.proxy.signIn();

    const projectListResponse = await this.proxy.db_meta_project_list();
    this.projects = projectListResponse.list;

    this.projectInfo = NocoDbProjectProxy._getTargetProject(this.projects, this.projectTitle);
    // http://localhost:8080/api/v1/db/meta/projects/{projectId}/tables
    {
      const url = `${this.proxy.nocoDbConfig.httpServer}/api/v1/db/meta/projects/${this.projectInfo.id}/tables`;
      const tableListingValue: ITableListing = await this.proxy.get(url);
      this.tableListing = new TableListing(tableListingValue);
      NocoDbProjectProxy._log.info('answer.tableListing.value', this.tableListing.value);
    }
  }

  constructor(public nocoDbConfig: INocoDbConfiguration,
              public projectTitle: string,
              public firebase: FirebaseConnectionService) {
  }
}
