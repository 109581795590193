import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {INocoProduct} from "./NocoProduct";


export interface INocoProductEvaluation {

  Id: number;
  EvaluationPage: number;
  QuestionId: number;
  QuestionNo: number;

}
export class NocoProductEvaluation extends ValueObject<INocoProductEvaluation>{

  protected onSetValue(value: INocoProductEvaluation | null) {
  }

  constructor( value: INocoProductEvaluation | null ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }

}


export class NocoProductEvaluationSet {

  evaluationSections: NocoProductEvaluation[] = [];
  evaluationSectionsById: {[id: number]: NocoProductEvaluation} = {};

  private static _getTableName( projectProxy: NocoDbProjectProxy ): string {
    if( projectProxy.isNocoDbVersion202Plus) {
      return 'product_evaluation';
    }
    return 'ProductEvaluation';
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProductEvaluation>> {

    const tableName = this._getTableName( proxy );
    return proxy.getView<INocoProductEvaluation>( tableName );
  }


  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductEvaluationSet> {

    const value = await NocoProductEvaluationSet.getValue( proxy );
    return new NocoProductEvaluationSet( value );
  }

  public constructor( public value: IListingReponse<INocoProductEvaluation> ) {

    for( const rowValue of value.list ) {

      const reference = new NocoProductEvaluation( rowValue );
      this.evaluationSections.push( reference );
      this.evaluationSectionsById[rowValue.Id] = reference;
    }
  }

}

